<template>
  <div>
    <div class="super">
      <div class="holder">
        <SmallNav />
        <div class="properties_count">
          <img
            @click="backToProperty()"
            src="../assets/images/thickbackarrow.svg"
            alt="back"
          />
          <h6 @click="backToProperty()">Propert List</h6>
        </div>

        <div class="container">
          <div class="search_holder">
            <div class="prop_facility_header">
              <p>Property Facilities</p>
            </div>
            <div class="property_details">
              <div class="days_left">
                <h3>365</h3>
                <h6>Days Left</h6>
              </div>
              <div class="details">
                <div>
                  <img
                    src="../assets/images/verified-white.svg"
                    alt="verified"
                  />
                  <small>Verified</small>
                </div>
                <h2>3 Bedroom Fully Detached Duplex</h2>
                <small>Maitama</small>
              </div>
            </div>

            <div>
              <div class="form-group">
                <input
                  type="text"
                  name="search"
                  class="form-control col-md-10 col-sm-12 input-bar"
                  id="search"
                  placeholder="| Sort/Search facilities"
                />
              </div>
            </div>

            <div class="search_result">
              <div @click="goToFacilityCheck">
                <p>Swimming pool</p>
                <span class="forward_arrow">
                  <img
                    src="../assets/images/shortblackarrow.svg"
                    alt="forward"
                  />
                </span>
              </div>
              <div>
                <p>Children playround</p>
                <span class="forward_arrow">
                  <img
                    src="../assets/images/shortblackarrow.svg"
                    alt="forward"
                  />
                </span>
              </div>
              <div>
                <p>Elevator</p>
                <span class="forward_arrow">
                  <img
                    src="../assets/images/shortblackarrow.svg"
                    alt="forward"
                  />
                </span>
              </div>
              <div>
                <p>Elevator</p>
                <span class="forward_arrow">
                  <img
                    src="../assets/images/shortblackarrow.svg"
                    alt="forward"
                  />
                </span>
              </div>
              <div>
                <p>Elevator</p>
                <span class="forward_arrow">
                  <img
                    src="../assets/images/shortblackarrow.svg"
                    alt="forward"
                  />
                </span>
              </div>
              <div>
                <p>Elevator</p>
                <span class="forward_arrow">
                  <img
                    src="../assets/images/shortblackarrow.svg"
                    alt="forward"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import PageLoader from "./PageLoader.vue";
import { Service } from "../store/service";
import moment from "moment";
const Api = new Service();
export default {
  name: "FacilityIssue",
  components: {
    SmallNav
    // PageLoader,
  },
  data() {
    return {
      PageLoad: false,
      detailsProps: ""
    };
  },
  mounted() {
    // this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.detailsProps = Api.getPropertyDetails();
    console.log("PropId", JSON.stringify(this.detailsProps.id));
    // if (this.detailsProps.id === undefined || this.detailsProps.id === null) {
    //   this.$router.push({
    //     path: `/dashboard/rented`,
    //   });
    // }
  },
  methods: {
    backToProperty() {
      this.$router.push({
        path: `/dashboard/landlordproperties`
      });
    },
    goToFacilityCheck() {
      this.$router.push({
        path: `/dashboard/facilitycheck`
      });
    }
  },
  // computed: {
  //   getdata() {
  //     return this.this.fetchPropertySchdeule();
  //   }
  // },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
    // moment: function(date) {
    //   return moment(date).format("MMMM DD YYYY, h:mm:ss a");
    // },
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$yellow: #ffb100;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  background-color: #efefee;
}
.properties_count {
  text-align: left;
  padding: 30px 20px;
  img {
    cursor: pointer;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: $black;
    cursor: pointer;
  }
}

.search_holder {
  background: $secondary;
  border-radius: 10px;
  margin: auto;
  max-width: 992px;
  width: 100%;
}

.prop_facility_header {
  padding: 19px 26px;
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 10px 10px 0 0;
  //  transform: matrix(1, 0, 0, -1, 0, 0);
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: $secondary;
    margin-bottom: 0;
  }
}

.days_left {
  width: 67px;
  height: 67px;
  //   position: absolute;
  position: relative;
  background: $secondary;
  border-radius: 50%;
  padding-top: 18px;
  margin: 7px;
  border: 1px solid #768fe9;
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: $primary;
    margin-bottom: 0;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    text-align: center;
    color: $primary;
  }
}

.property_details {
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  display: grid;
  grid-template-columns: 10% 1fr;
  column-gap: 5px;
  padding: 15px;
}

.details {
  text-align: left;
  small {
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 12px;
    // display: flex;
    align-items: center;
    color: $secondary;
    margin-left: 10px;
  }
  h2 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 18px;
    // display: flex;
    align-items: center;
    color: $secondary;
    margin-top: 10px;
  }
}
.input-bar {
  background: $secondary;
  height: 44px;
  border: 0.5px solid #cecece;
  // border: 0.5px solid #a2b0e3;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0px;
  padding: 20px 15px;
  max-width: 858px;
  width: 100%;
  height: 59px;
  margin: 40px auto;
}

.search_result {
  margin: auto;
  max-width: 858px;
  width: 100%;
  margin-bottom: 40px;
  padding-bottom: 40px;
  // margin: 0 15px;
  div {
    border: 0.2px solid #cecece;
    padding: 16px 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-family: Gotham;
      font-style: normal;
      font-weight: 900;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 0;
    }
  }
  div:hover {
    background: #cae6ff;
    cursor: pointer;
    border: 1px solid transparent;
    height: inherit;
    .forward_arrow {
      display: block;
    }
  }
}

.forward_arrow {
  display: none;
}
// .forward_arrow:hover {
//   display: block;
// }

@media screen and (max-width: 599px) {
  .property_details {
    grid-template-columns: 24% 1fr;
  }
  .details {
    h2 {
      line-height: 25px;
    }
  }
  .days_left {
    width: 55px;
    height: 55px;
    padding-top: 10px;
    h3 {
      font-size: 13px;
    }
    h6 {
      font-size: 9px;
    }
  }
}
</style>
